@import url('https://fonts.googleapis.com/css?family=El+Messiri');

body {
    font-family: 'El Messiri', sans-serif;
    padding: 0;
    margin: 0;
    background: #00b3fa;
}

a {
  text-decoration: none;
}

.contanier {
  display: block;
  margin: 0 auto;
  width: 75%;
}

.logo {
  width: 50%;
}

main {
  display: inline-block;
  width: 75%;
  vertical-align: top;
  padding: 5px;
  margin-bottom: 15px;
}


.sidebar {
  display: inline-block;
  width: 25%;
  vertical-align: top;
  padding: 5px;
}

nav {
  display: inline-block;
  width: 18%;
  vertical-align: top;
  padding: 5px;
}

nav a {
  display: block;
  padding: 5px;
  text-decoration: none;
  color: #fff;
  font-size: 0.7em;
  font-weight: bold;
  border-bottom: 1px solid #fff;
  border-radius: 15px;
}

nav a:hover {
  background: #fff;
  color: #00b3fa;
}

.list-view .list-item {
  display: block;
  padding: 15px;
  margin: 3px;
  text-decoration: none;
  background: #fff;
  color: #00b3fa;
  box-shadow: 0 5px 15px #ccc;
}

.list-view .list-item:first-child {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.list-view .list-item:last-child {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.list-view .list-item:hover {
  background: #e0f7ff;
}

input[type="text"], input[type="date"], input[type="number"], input[type="email"], input[type="password"] {
  display: block;
  /* width: 90%; */
  padding: 5px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

select {
  display: block;
  width: 90%;
  padding: 5px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;

}

textarea {
  display: block;
  width: 90%;
  padding: 5px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  
}

.card {
  display: block;
  width: 100%;
  border-radius: 15px;
  background: #fff;
  padding: 15px;
  margin: 0;
  margin-bottom: 15px;
}

.btn {
  display: inline-block;
  color: #00b3fa;
  background: #fff;
  padding: 5px;
  margin: 3px;
  border-radius: 15px;
  text-decoration: none;
  font-size: 0.5em;
  vertical-align: top;
  cursor: pointer;
}

.btn:hover, button:hover {
  background: #e0f7ff;
  color: #00b3fa;
}

button {
  display: block;
  color: #fff;
  background: #00b3fa;
  padding: 15px;
  margin-top: 15px;
  border: none;
  border-radius: 15px;
}

button:disabled {
  color: #ccc;
}

.inline-list .inline-item {
  display: inline-block;
  vertical-align: top;
  padding: 5px;
}

.tag {
  display: inline-block;
  padding: 5px;
  margin: 2px;
  background: #00b3fa;
  color: #fff;
  border-radius: 15px;
  font-size: 0.7em;
}

.col-1 {
  width: 90%;
}

.col-2 {
  width: 70%;
}

.col-3 {
  width: 45%;
}

.col-4 {
  width: 25%;
}

.col-5 {
  width: 10%;
}

.col-6 {
  width: 38%;
}

.mrgn {
  margin: 12px;
}

.center {
  text-align: center;
}

.footer {
  background: #0037cf;
  color: #fff;
  text-align: center;
  padding: 5%;
}

.pl {
  height: 100px;
}

.center-div {
  margin: 15px auto;
}

.error {
  color: #ff0000;
  font-size: 0.7em;
}

.mobile-btn {
  display: none;
}

.mobile-menu {
  display: block;
}

@media only screen and (max-width: 768px) {
  .contanier, .col-2, .col-3, .col-4, .col-5, .col-6, nav {
    display: block;
    width: 95%;
    margin: 5px auto;
  }

  .inline-list .inline-item {
    display: block !important;
  }

  .mobile-btn {
    display: block;
  }

  .mobile-menu {
    display: none;
  }

}

.loading {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 9999;
  text-align: center;
}

.loading img {
  display: block;
  position: absolute;
  margin: 0 auto;
  top: 45%;
  left: 0;
  right: 0;
  width: 25%;
}

.hidden {
  display: none;
}